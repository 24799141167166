.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*disable */
.disable{
  pointer-events:none;
}

/*My player code*/
.player1{
  z-index: 1;
  height: 30px;
  width: 30px;
}
.playerbounce{
  transition-duration: 0.5s;
}
.player2{
  z-index: 2;
  height: 30px;
  margin-left: 5px;
  width: 30px;
}
.player3{
  z-index: 3;
  height: 30px;
  margin-left: 10px;
  width: 30px;
}
.player4{
  z-index: 3;
  height: 30px;
  margin-left: 15px;
  width: 30px;
}
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.ant-btn {
  /* padding: 8px 20px; */
  text-align: center;
 background-color: #a77cb6;
/* display: none; */
  color: #fff !important;
  border-radius: 20px;
  text-transform: uppercase;
  /* font-weight: 500; */
  border-color: transparent !important;
}
.ant-btn-primary{
  background-color: #a77cb6 !important;
  border-color: transparent !important;
}
.card-wrapper{
  width: 100% !important;
}

/* -ms-flex: 1;
-webkit-flex: 1;
flex: 1; */